@import 'Styles/includes';

.SidebarMenu {
    $root: &;

    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1.4rem;

    @include media(ML) {
        margin-bottom: 40px;
        font-size: 1.6rem;
        border-bottom: 1px solid $grey-20;
    }

    &::before {
        content: '';
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black, .3);
        opacity: 0;
        animation-fill-mode: forwards;
        animation-duration: .6s;
        animation-delay: .01s;
        z-index: $z-SidebarMenu__List - 1;

        @include media(ML) {
            display: none !important;
        }
    }

    &--Open {
        &::before {
            display: block;

            :global {
                animation-name: fadeIn;
            }
        }
    }

    &--Closed {
        &::before {
            display: none !important;
        }
    }

    &__Toggle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 56px;
        padding: 13px 28px 13px 20px;
        margin: 0;
        color: $red;
        font-size: 1.4rem;
        border: none;
        border-radius: 4px;
        background-color: $red-5;
        box-shadow: none;
        cursor: pointer;

        @include media(ML) {
            display: none;
        }

        svg:first-child {
            width: 24px;
            height: 24px;
            margin-right: 12px;
            fill: $red;
        }

        svg:last-child {
            width: 8px;
            height: 12px;
            margin-left: auto;
            transform: rotate(90deg);
            transition: transform .3s ease-in-out;

            #{$root}--Open & {
                transform: rotate(-90deg);
            }
        }

        #{$root}--Open & {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            z-index: $z-SidebarMenu__List;
        }
    }

    &__List {
        flex: 0 0 auto;
        display: none;
        flex-direction: column;
        width: 100%;

        #{$root}--Open > &,
        #{$root}__Item--Open > & {
            display: flex;
        }

        #{$root} > & {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            padding-bottom: 8px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background: white;
            z-index: $z-SidebarMenu__List;

            @include media(ML) {
                display: flex !important;
                position: relative;
                top: auto;
                padding-bottom: 0;
                border-radius: 0;
                z-index: auto;
            }
        }
    }

    &__Item {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__Content {
        position: relative;
    }

    &__Link {
        flex: 1 0 auto;
        position: relative;
        display: block;
        padding: 13px 20px;
        font-weight: $bold;
        line-height: 1.52;
        border-top: 1px solid $grey-20;
        transition: background-color $transition, color $transition;

        @include media(ML) {
            padding: 15px 24px;
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: 0;
            width: 5px;
            background: $red;
        }

        #{$root}__Item--HasChildren > #{$root}__Content & {
            margin-right: 48px;

            @include media(ML) {
                margin-right: 56px;
            }
        }

        #{$root}__List #{$root}__List & {
            padding-left: 20px + 12px;
            font-weight: $regular;

            @include media(ML) {
                padding-left: 24px + 16px;
            }
        }

        #{$root}__List #{$root}__List #{$root}__List & {
            padding-left: 20px + (12px * 2);

            @include media(ML) {
                padding-left: 24px + (16px * 2);
            }
        }

        #{$root}__List #{$root}__List #{$root}__List #{$root}__List & {
            padding-left: 20px + (12px * 3);

            @include media(ML) {
                padding-left: 24px + (16px * 3);
            }
        }

        #{$root}__List #{$root}__List #{$root}__List #{$root}__List #{$root}__List & {
            padding-left: 20px + (12px * 4);

            @include media(ML) {
                padding-left: 24px + (16px * 4);
            }
        }

        #{$root}__List #{$root}__List #{$root}__List #{$root}__List #{$root}__List #{$root}__List & {
            padding-left: 20px + (12px * 5);

            @include media(ML) {
                padding-left: 24px + (16px * 5);
            }
        }

        &:hover {
            color: $red;
            background-color: $red-5;
        }

        #{$root}__Item--Open > #{$root}__Content & {
            &::before {
                display: block;
                background-color: $red-20;
            }
        }

        #{$root}__Item--Active > #{$root}__Content & {
            background-color: $red-5;

            &::before {
                display: block;
                background-color: $red !important;
            }
        }
    }

    &__Expand {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0 0 0 auto;
        width: 48px;
        border: none;
        border-top: 1px solid $grey-20;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        transition: background-color $transition;
        cursor: pointer;

        @include media(ML) {
            width: 56px;
        }

        &:hover,
        #{$root}__Link:hover + & {
            background-color: $red-5;
        }

        #{$root}__Item--Active > #{$root}__Content &,
        #{$root}__Item--Open > #{$root}__Content & {
            // background-color: $red-5;
        }

        &::before {
            content: '';
            display: inline-block;
            position: relative;
            top: 2px;
            width: 18px;
            height: 18px;
            background-color: $grey-90;
            // Make the icon into a "+"
            clip-path: polygon(8px 10px, 8px 18px, 10px 18px, 10px 10px, 18px 10px, 18px 8px, 10px 8px, 10px 0px, 8px 0px, 8px 8px, 0px 8px, 0px 10px);

            #{$root}__Item--Open > #{$root}__Content #{$root}__Link + & {
                // Make the icon into a "-"
                clip-path: polygon(0px 8px, 18px 8px, 18px 10px, 0px 10px);
            }
        }
    }
}


@import 'Styles/includes';

.Documents {
    $root: &;

    &__List {
        border-top: 1px solid $grey-20;
    }

    &__Item {
        padding: 8px 32px;
        border-bottom: 1px solid $grey-20;

        @include media(M) {
            padding: 16px 40px;
        }
    }

    &__Link {
        @include textstyle(body-regular);

        span {
            display: inline-block;
        }
    }

    &__LinkText {
        position: relative;
        margin-right: 28px;
        color: $red;
        border-bottom: 1px solid transparent;
        transition: border-color $transition;

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 6px;
            right: -20px;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            background: url('#{$assetsPath}/img/download--red.svg') no-repeat center center !important;
            background-size: contain;

            @include media(M) {
                top: 9px;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__Meta {
        margin-right: 8px;
    }

    &__Meta,
    &__Description {
        color: $base-color;
        font-size: 1.4rem;
    }
}
